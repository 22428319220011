// scss-docs-start page-header-variables
$header-slider-content-padding-bottom: 4.5rem;
// scss-docs-end page-header-variables

// scss-docs-start main-nav
$headerHeight: 90px;
$navHeight: 90px;
$navPadding: 0.5rem 0;
// scss-docs-end main-nav

// scss-docs-start filter
$filter-form-bg-color: $primary;
$filter-form-color: $white;
$filter-form-title-margin: 1.5rem 0 0 0;
$filter-form-inputs-margin: 1.5rem 0 0 0;
$filter-form-submit-margin: 0 !default;
$filter-form-info-margin: 1.2rem 0 0 0;
$filter-form-input-bg-color: transparent !default;
$filter-form-input-border-color: $secondary;
$filter-form-input-placeholder-color: #f2d58f;
$filter-form-input-placeholder-hover-color: $secondary;
$filter-form-input-text-color: $secondary;
$filter-form-input-chevron-color: encodecolor(#f2d58f);
$filter-form-input-chevron-hover-color: encodecolor($secondary);
$filter-form-calendar-year-label-bg-color: $gray-800;
$filter-form-calendar-month-hover-bg-color: $gray-600;
$filter-form-calendar-month-active-bg-color: $signal !default;
$filter-form-calendar-month-disabled-opacity: 0.5;
$filter-form-badge-bg-color: $gray-700;
$filter-lead-form-submit-btn-margin-top: 0;
$filter-lead-form-submit-btn-font-family: $theme-font-bold;
$filter-lead-form-submit-btn-font-size: 4.3rem;
$filter-lead-form-submit-btn-color: $secondary;
$filter-lead-form-submit-btn-hover-color: $signal !default;
// scss-docs-end filter

// scss-docs-start heading-block
$cms-heading-block-margin-bottom: 0.5rem;
// scss-docs-end heading-block

// scss-docs-start contact-banner
$contact-banner-bg-color: $secondary;
$contact-banner-color: $primary;
// scss-docs-end contact-banner

// scss-docs-start newsletter-block
$newsletter-block-title-color: $white;
$newsletter-block-font-family: $theme-font-bold;
$newsletter-block-color: $tertiary !default;
$newsletter-block-color-hover: $primary;
// scss-docs-end newsletter-block

// scss-docs-start travel-program-block
$travel-itinerary-block-margin-top: 3rem;
$travel-itinerary-block-margin-bottom: 7rem;
$travel-itinerary-block-program-wrap-max-height: 400px;
// scss-docs-end travel-program-block

// scss-docs-start travel-pricing-block
$pricing-block-margin-top: 0 !default;
$pricing-block-margin-bottom: 7rem;
$pricing-block-title-margin-bottom: 1.5rem;
$pricing-block-info-outer-padding: 0 3rem 0 0;
$pricing-block-info-inner-padding: 2rem 1rem 3rem 1rem;
$pricing-block-info-inner-padding-print: 2rem 1rem 2rem 1rem;
$pricing-block-info-pricing-info-color: $gray-300;
$pricing-block-services-ul-margin: 0 0 2rem 0;
// scss-docs-end travel-pricing-block

// scss-docs-start travel-guide-block
$travel-guide-block-margin-top: 0 !default;
$travel-guide-block-margin-bottom: 7rem;
$travel-guide-block-margin-bottom-print: 3rem !default;
$travel-guide-block-title-margin-bottom: 1.5rem;
// scss-docs-end travel-guide-block

// scss-docs-start travel-teams-block
$teams-block-margin-top: 0 !default;
$teams-block-margin-bottom: 7rem;
$teams-block-title-margin-bottom: $travel-guide-block-title-margin-bottom;
// scss-docs-end travel-teams-block

// scss-docs-start travel-accommodation-block
$travel-accommodation-block-margin-top: 1rem;
$travel-accommodation-block-margin-bottom: 7rem;
// scss-docs-end travel-accommodation-block

// scss-docs-start travel-gallery-block
$travel-gallery-block-margin-top: 0 !default;
$travel-gallery-block-margin-bottom: 5rem;
// scss-docs-end travel-gallery-block

// scss-docs-start init-booking
$init-booking-base-price-label-font-family: inherit !default;
$init-booking-base-price-label-font-size: 0.8rem;
$init-booking-base-price-from-font-family: inherit !default;
$init-booking-base-price-from-font-size: 0.7rem !default;
$init-booking-base-price-price-font-family: $theme-font-bold;
$init-booking-base-price-price-font-size: 1.85rem;
// scss-docs-start init-booking

// scss-docs-start booking-summary
$booking-summary-primary-color: auto !default;
$booking-summary-secondary-color: $gray-400;
// scss-docs-end booking-summary
