//@import url('https://use.typekit.net/jbc8vuy.css');
@font-face {
  font-family: 'komet';
  src: url('https://use.typekit.net/af/90b8b0/00000000000000007735a8fa/30/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n3&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/90b8b0/00000000000000007735a8fa/30/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n3&v=3')
      format('woff'),
    url('https://use.typekit.net/af/90b8b0/00000000000000007735a8fa/30/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n3&v=3')
      format('opentype');
  font-display: swap;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
}

@font-face {
  font-family: 'komet';
  src: url('https://use.typekit.net/af/66c5aa/00000000000000007735a904/30/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/66c5aa/00000000000000007735a904/30/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/66c5aa/00000000000000007735a904/30/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3')
      format('opentype');
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: 'komet-heavy';
  src: url('https://use.typekit.net/af/b90f1c/00000000000000007735a91a/30/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n8&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/b90f1c/00000000000000007735a91a/30/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n8&v=3')
      format('woff'),
    url('https://use.typekit.net/af/b90f1c/00000000000000007735a91a/30/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n8&v=3')
      format('opentype');
  font-display: swap;
  font-style: normal;
  font-weight: 800;
  font-stretch: normal;
}
