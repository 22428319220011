.card-title {
  line-height: 1;
  font-size: 1.5rem;
  margin-bottom: 0.8rem;
  font-family: $theme-font-bold;
  @include font-uppercase-bold;

  @include media-breakpoint-up(md) {
    font-size: 1.5rem;
  }
}

.map-marker-label {
  margin-top: -10px;
  margin-left: -1px;
}
