@mixin font-uppercase {
  text-transform: uppercase;
  letter-spacing: 0.08rem;
}

@mixin heading-block-h1 {
  font-family: $theme-font-bold;
  text-transform: uppercase;
  color: $primary;

  display: inline-block;
  font-size: 2.3rem;
  margin-bottom: 1rem;

  @include media-breakpoint-up(sm) {
    font-size: 2.6rem;
  }
  @include media-breakpoint-up(md) {
    font-size: 2.6rem;
  }
  @include media-breakpoint-up(lg) {
    font-size: 2.8rem;
  }
  @include media-breakpoint-up(xl) {
    font-size: 3rem;
  }
}

@mixin heading-h2 {
  @include heading-block-h1;
}

@mixin heading-block-h4 {
  @include font-bold;

  display: inline-block;
  font-size: 1.8rem;
  text-transform: uppercase;
  margin-bottom: 1rem;

  @include media-breakpoint-up(sm) {
    font-size: 2rem;
  }
  @include media-breakpoint-up(md) {
    font-size: 2.3rem;
  }
  @include media-breakpoint-up(lg) {
    font-size: 2.5rem;
  }
  @include media-breakpoint-up(xl) {
    font-size: 3rem;
  }
}

@mixin font-title-h3($withMargin: true) {
  font-family: $theme-font-bold;
  font-size: 1.5rem;
  color: $primary;
  @include font-uppercase-bold;

  @if $withMargin == true {
    margin-bottom: 0.8rem;
  }
}

@mixin font-title-h4($withMargin: true) {
  font-family: $theme-font-bold;
  font-size: 1.3rem;
  color: $primary;
  @include font-uppercase-bold;

  @if $withMargin == true {
    margin-bottom: 0.5rem;
  }
}

@mixin font-content-subtitle($withMargin: true) {
  font-size: 1.1rem;
  font-family: $theme-font-bold;
  @include font-uppercase-bold;

  @if $withMargin == true {
    margin-bottom: 0.35rem;
  }
}

// start - jumbo title
@mixin jumbo-title {
  font-family: $theme-font-bold;
  font-size: 2.8rem;
  @include font-bold;

  @include media-breakpoint-up(md) {
    margin-bottom: 2rem;
    font-size: 3.5rem;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 2rem;
    font-size: 4.5rem;
  }
}

@mixin jumbo-subtitle {
  font-size: 1.15rem;
  @include font-uppercase-bold;

  @include media-breakpoint-up(sm) {
    font-size: 1.3rem;
  }
}
// end - jumbo title
