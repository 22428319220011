@import 'styles/helpers/global_variables';

.navbar-light {
  @include media-breakpoint-down(lg) {
    #main-nav {
      .navbar-collapse {
        position: fixed;
        width: 100vw;
        top: 72px;
        left: 0;
        background: rgba(255, 255, 255, 0.97);
        padding: 10px;
        $animationDuration: 80ms;

        transition: height $animationDuration linear;

        &.show {
          height: 100vh;
        }
      }
    }
  }

  .main-nav {
    text-transform: uppercase;

    & > .nav-link,
    & > .nav-item > .nav-link {
      background-color: $primary;
      color: $white;
      border: 1px solid $black;
      padding: 8px 9px;
      margin: 7px 0;

      @include media-breakpoint-up(xl) {
        margin: 0 7px;
      }
    }

    .nav-item.dropdown > a::after {
      //display: none;
    }

    .dropdown-toggle::after {
      float: right;
      margin-top: 6px;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    }
    @include media-breakpoint-up(xl) {
      float: none;
      margin-top: inherit;
    }
    $triangleSize: 15px;

    .dropdown-menu {
      border: none;
      background: rgba(255, 255, 255, 0.8);
      box-shadow: 0 0 18px -7px rgb(0 0 0 / 50%);

      @include media-breakpoint-up(xl) {
        margin-top: $triangleSize !important;
        right: auto;
        left: 50%;
        -webkit-transform: translate(-50%, 0);
        -o-transform: translate(-50%, 0);
        transform: translate(-50%, 0);

        &::before {
          // position
          right: auto;
          left: 50%;
          -webkit-transform: translate(-50%, 0);
          -o-transform: translate(-50%, 0);
          transform: translate(-50%, 0);

          // triangle
          border-bottom: $triangleSize solid rgba(255, 255, 255, 0.82);
          border-left: $triangleSize solid rgba(0, 0, 0, 0);
          border-right: $triangleSize solid rgba(0, 0, 0, 0);
          content: '';
          display: inline-block;
          position: absolute;
          top: -$triangleSize;
        }
      }

      .dropdown-item {
        font-size: 0.9rem;
        color: #464646;
        padding-bottom: 0;
        padding-top: 0.5rem;

        &:hover,
        &:focus {
          color: $black;
          background: none;
        }

        & > div {
          border-bottom: 1px dashed #464646;
          padding-bottom: 0.5rem;
        }

        &:last-child > div {
          border-bottom: none;
        }
      }
    }

    .icon-links {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 1rem;
      margin-bottom: 0.5rem;

      .icon-link {
        flex: 0 0 auto;
        padding: 0 0.8rem;

        .icon {
          @include icon-size(1.7);
        }
      }

      @include media-breakpoint-up(xl) {
        margin-top: 0;
        margin-bottom: 0;

        .icon-link {
          padding: 0 0.3rem;
        }
      }
    }
  }
}
