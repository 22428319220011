$cloudinaryThemeCloudBaseUrl: 'https://res.cloudinary.com/difp9jq5j/image/upload/themes/pkag/';
[class^='si-']::before,
[class*=' si-']::before {
  width: 0.8rem;
  margin-right: 0;
  margin-left: 0;
  line-height: 1rem;
  font-size: 3rem;
  vertical-align: bottom;
}
